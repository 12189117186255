import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface NavLinkProps {
  path: string;
  name: string;
  key: string;
  className?: string;
}

export const NavLink: React.FC<NavLinkProps> = ({ path, name, className: propClassName }) => {
  const location = useLocation();
  const isActive = location.pathname === path || location.pathname.startsWith(`${path}/`);

  return (
    <Link
      to={path}
      className={twMerge(
        "relative flex border-b-[1.5px] border-transparent px-2 py-1 align-middle transition-all",
        "hover:border-neutral-800 hover:text-neutral-800 dark:hover:border-neutral-200 dark:hover:text-neutral-200",
        isActive &&
          "border-neutral-800 text-neutral-800 dark:border-neutral-400 dark:text-neutral-200",
        propClassName
      )}
    >
      {name}
    </Link>
  );
};
