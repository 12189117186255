import { twMerge } from "tailwind-merge";
/*
    This file contains the Linkify component that is used to convert certain strings to links.
    This is used in the Work component to convert the company name to a link.

    str: The string to be converted to a link.
    nt: target="_blank" is set to true if nt is set to true.
*/

export function Linkify({ str, className, nt = false }: { str: string; className?: string; nt?: boolean }) {
  const _str = str.trim();
  const target = nt ? "_blank" : "_self";
  const linkClass = className ? className : "link";
  const common_styles = "opacity-75 italic";

  switch (_str) {
    case "Pointo":
      return (
        <a href="https://www.pointo.in" target={target} className={twMerge(linkClass, common_styles)}>
          Pointo
        </a>
      );
    case "Angular":
      return (
        <a
          href="https://angular.io"
          target={target}
          className={twMerge(linkClass, "!text-red-700 no-underline", common_styles)}
        >
          Angular
        </a>
      );
    case "Node.js":
      return (
        <a
          href="https://nodejs.org"
          target={target}
          className={twMerge(linkClass, "!text-green-700 no-underline", common_styles)}
        >
          Node.js
        </a>
      );
    case "React.js":
      return (
        <a
          href="https://reactjs.org"
          target={target}
          className={twMerge(linkClass, "!text-blue-600 no-underline", common_styles)}
        >
          React.js
        </a>
      );
    case "MongoDB":
      return (
        <a
          href="https://www.mongodb.com"
          target={target}
          className={twMerge(linkClass, "!text-green-500 no-underline", common_styles)}
        >
          MongoDB
        </a>
      );
    case "Express.js":
      return (
        <a
          href="https://expressjs.com"
          target={target}
          className={twMerge(linkClass, "!text-gray-700 no-underline dark:!text-gray-50", common_styles)}
        >
          Express.js
        </a>
      );
    case "Google Analytics":
      return (
        <a
          href="https://analytics.google.com"
          target={target}
          className={twMerge(linkClass, "!text-yellow-500 no-underline", common_styles)}
        >
          Google Analytics
        </a>
      );
    default:
      return (
        <a href="#" target={target} className={twMerge(linkClass, "no-underline", common_styles)}>
          {_str}
        </a>
      );
  }
}
