import { Link } from "react-router-dom";

import { withDefault } from "../layouts/Default";
import { Metadata } from "../types/types";

const metadata: Metadata = {
  title: "Page not found",
  description: "Page not found",
};

const NotFound = () => (
  <main className="flex flex-col items-center justify-center min-h-[50vh] rounded-lg">
    <div className="text-center">
      <h1 className="text-4xl font-bold mb-4">Page not found</h1>
      <p className="text-lg mb-6">Oops! The page you are looking for doesn't exist.</p>
      <Link
        className="inline-block px-12 py-[0.4rem] mt-2 text-lg font- text-white bg-blue-600 rounded-md shadow-lg transition duration-300 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
        to="/"
      >
        Home
      </Link>
    </div>
  </main>
);

export default withDefault(<NotFound />, metadata);
