import { NavLink } from "./NavLink";

const navItems = {
  "/": {
    name: "Home",
  },
  "/work": {
    name: "Work",
  },
  "/blog": {
    name: "Projects",
  },
  "/contact": {
    name: "Contact",
  },
};

export default function Navbar() {
  return (
    <aside className="-ml-[8px] mb-8 tracking-tight">
      <div className="lg:sticky lg:top-20">
        <nav
          className="fade relative flex scroll-pr-6 flex-row items-start px-0 pb-0 md:relative md:overflow-auto"
          id="nav"
        >
          <div className="flex flex-row gap-1 space-x-0">
            {Object.entries(navItems).map(([path, { name }]) => (
              <NavLink key={path} path={path} name={name} />
            ))}
          </div>
        </nav>
      </div>
    </aside>
  );
}
