import { CiLocationOn } from "react-icons/ci";

import { withDefault } from "../../layouts/Default";
import TechIconsSection from "./TechIcons";
import {
  SiArduino,
  SiTableau,
  SiPython,
  SiCplusplus,
  SiReact,
  SiRailway,
  SiNodedotjs,
  SiBun,
} from "react-icons/si";
import { Metadata } from "../../types/types";

// import ship from "/images/home/ship.webp";
// import summit from "/images/home/summit.webp";
// import filming from "/images/home/filming.webp";
// import meetups from "/images/home/meetups.webp";
// import smashing from "/images/home/smashing.webp";
// import reactathon from "/images/home/reactathon.webp";

const metadata: Metadata = {
  title: "Home",
  description: "Suraj Mandal's personal website, showcasing his work and projects.",
};

function Home() {
  const techIcons = [
    { href: "https://www.arduino.cc/", icon: SiArduino, name: "Arduino" },
    { href: "https://www.python.org/", icon: SiPython, name: "Python" },
    { href: "https://www.tableau.com/", icon: SiTableau, name: "Tableau" },
    { href: "https://react.dev", icon: SiReact, name: "React" },
    { href: "https://nodejs.org", icon: SiNodedotjs, name: "Node.js" },
    { href: "https://isocpp.org/", icon: SiCplusplus, name: "C++" },
    { href: "https://railway.app", icon: SiRailway, name: "Railway" },
  ];

  return (
    <section>
      <h1 className="mb-4 text-2xl tracking-tighter">Greetings, I'm Suraj Mandal</h1>
      <h3 className="font-medium dark:font-normal mb-2 flex text-slate-700 dark:text-gray-300">
        Full Stack Developer based in Canada{" "}
        <CiLocationOn className="ml-2 mt-[0.15rem] opacity-100" />
      </h3>
      <p className="prose-md text-slate-700 dark:prose-invert dark:text-[#d6d3d1dd] leading-relaxed">
        Creating next-gen tech: blending full-stack development, IoT, and cloud computing to turn
        complex ideas into impactful solutions. From developing real-time voice cloning apps to
        optimizing flight paths with Arduino & Openlog, I thrive on pushing technological
        boundaries. Currently pursuing Business Intelligence & System Infrastructure, I'm seeking
        co-op opportunities in Canada for Summer 2024 to apply my diverse skills in impactful
        projects.
      </p>
      <ul className="font-sm mt-5 flex flex-col space-x-0 space-y-2 text-neutral-600 md:flex-row md:space-x-6 md:space-y-0 dark:text-neutral-300">
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            target="_blank"
            href="https://github.com/surajmandalcell"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Github</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            target="_blank"
            href="https://linkedin.com/in/mandalsuraj"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Linkedin</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            href="/work"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">Work History</p>
          </a>
        </li>
        <li>
          <a
            className="flex items-center transition-all hover:text-neutral-800 dark:hover:text-neutral-100"
            rel="noopener noreferrer"
            href="/blog?tag=project"
          >
            <ArrowIcon />
            <p className="ml-2 h-7">My Projects</p>
          </a>
        </li>
      </ul>

      {/* Gallery bento */}

      {/* <div className="my-8 columns-2 gap-4 sm:columns-3 [&>div]:mb-4">
        <div className="relative h-40">
          <img
            alt="Me speaking on stage at React Summit about the future of Next.js"
            src={summit}
            sizes="(max-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover w-full h-full"
          />
        </div>
        <div className="relative h-80">
          <img
            alt="Me, at ubisoft toronto for secret game testing"
            src={filming}
            sizes="(max-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover object-[-16px] sm:object-center w-full h-full"
          />
        </div>
        <div className="relative h-40 sm:h-80">
          <img
            alt="Me standing on stage at Reactathon delivering the keynote"
            src={reactathon}
            sizes="(max-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover object-top sm:object-center w-full h-full"
          />
        </div>
        <div className="relative h-40">
          <img
            alt="Me standing on stage at SmashingConf giving a talk about my optimism for the web"
            src={smashing}
            sizes="(max-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover w-full h-full"
          />
        </div>
        <div className="relative h-40">
          <img
            alt="Me and Guillermo Rauch on stage for Vercel Ship, answering questions from the Next.js community"
            src={ship}
            sizes="(max-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover w-full h-full"
          />
        </div>
        <div className="relative h-80">
          <img
            alt="My badge on top of a pile of badges from a Vercel meetup we held"
            src={meetups}
            sizes="(min-width: 768px) 213px, 33vw"
            className="rounded-lg object-cover w-full h-full"
          />
        </div>
      </div> */}

      {/* Gallery bento end */}

      <TechIconsSection icons={techIcons} rootClass="mt-6" />
    </section>
  );
}

function ArrowIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default withDefault(<Home />, metadata);
