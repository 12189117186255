import { twMerge } from "tailwind-merge";

interface TechIconProps {
  href: string;
  icon: any;
  name?: string;
  useSvgSprite?: boolean;
}

const TechIcon = ({ href, icon: Icon, name, useSvgSprite }: TechIconProps) => (
  <div className="flex items-center justify-between rounded border border-neutral-200 bg-neutral-50 px-4 py-2 dark:border-neutral-700 dark:bg-neutral-800 text-neutral-600 dark:text-neutral-300 hover:opacity-70">
    <a
      href={href}
      className="flex flex-row font-bold"
      style={{
        gap: "0.4rem",
        alignItems: "center",
      }}
    >
      {useSvgSprite && name ? (
        <svg width="35" height="27" role="img" aria-label={`${name} logo`}>
          <use href={`/sprite.svg#${name.toLowerCase()}`} />
        </svg>
      ) : (
        <Icon />
      )}
      {name && <p className="text-[0.9rem]">{name}</p>}
    </a>
  </div>
);

const TechIconsSection = ({ icons, rootClass }: { icons: any; rootClass?: string }) => (
  <div className={twMerge("mb-8 flex flex-wrap gap-2", rootClass)}>
    {icons.map((icon, index) => (
      <TechIcon key={index} {...icon} />
    ))}
  </div>
);

export default TechIconsSection;
