import React, { useState, useEffect } from "react";
import { withDefault } from "../../layouts/Default";
import { Metadata } from "../../types/types";

const metadata: Metadata = {
  title: "Contact",
  description: "Contact me",
};

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const isAnonymousParam =
      url.searchParams.get("anonymous") !== null || url.pathname.includes("anonymous");
    setIsAnonymous(isAnonymousParam);
  }, []);

  const handleAnonymousToggle = (checked) => {
    setIsAnonymous(checked);
    const url = new URL(window.location.href);
    if (checked) {
      url.searchParams.set("anonymous", "true");
    } else {
      url.searchParams.delete("anonymous");
    }
    window.history.replaceState({}, "", url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("sending");

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: isAnonymous ? "anonymous@example.com" : email, message }),
      });

      if (response.ok) {
        setStatus("success");
        setEmail("");
        setMessage("");
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <div className="min-h-auto bg-white dark:bg-neutral-900 text-neutral-900 dark:text-white rounded-xl mx-[-1rem]">
      <div className="container mx-auto md:px-8 lg:px-12 py-12">
        <div className="flex flex-col lg:flex-row items-start justify-between gap-12 px-4">
          <div className="w-full lg:w-1/2">
            <div className="relative rounded-2xl overflow-hidden">
              <img
                src="/images/contact/suraj-mandal-unsplash.webp"
                alt="contact"
                className="w-full md:h-[500px] object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-neutral-600 dark:from-neutral-900 to-transparent"></div>
              <div className="absolute bottom-0 left-0 p-8" style={{ color: "#dfdfdf" }}>
                <h1 className="text-4xl font-bold mb-4 text-white">Contact</h1>
                <p className="text-lg">
                  Email:{" "}
                  <a href="mailto:me@mandalsuraj.com" className="hover:underline">
                    me@mandalsuraj.com
                  </a>
                </p>
                <p className="text-lg">
                  Phone:{" "}
                  <a href="tel:+14167264766" className="hover:underline">
                    +1 (416) 726-4766
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 pt-1">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-2">
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  value={isAnonymous ? "anonymous@anonymous.com" : email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isAnonymous}
                  className="w-full px-4 py-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                  placeholder="you@example.com"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="anonymous"
                  checked={isAnonymous}
                  onChange={(e) => handleAnonymousToggle(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="anonymous" className="text-sm">
                  Send anonymously
                </label>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  rows={4}
                  className="w-full px-4 py-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your message here..."
                />
              </div>
              <button
                type="submit"
                disabled={status === "sending"}
                className="w-full py-3 px-4 bg-blue-700 hover:bg-blue-900 rounded-lg text-white font-medium transition duration-300"
              >
                {status === "sending" ? "Sending..." : "Send message"}
              </button>
            </form>
            {status === "success" && (
              <div className="mt-4 p-4 bg-green-600 text-white rounded-lg">
                <span>Message sent successfully!</span>
              </div>
            )}
            {status === "error" && (
              <div className="mt-4 p-4 bg-red-600 text-white rounded-lg">
                <span>Failed to send message. Please try again.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withDefault(<ContactForm />, metadata);
