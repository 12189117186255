import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { Metadata } from "../types/types";

interface RootLayoutProps {
  children: React.ReactNode;
  metadata?: Metadata;
}

const Default: React.FC<RootLayoutProps> = ({ children, metadata }) => (
  <div className="dark bg-gray-100 text-black dark:bg-[#111010] dark:text-white">
    <Helmet>
      {metadata?.title && <title>{metadata.title}</title>}
      {metadata?.description && <meta name="description" content={metadata.description} />}
    </Helmet>
    <div className="mx-4 mb-40 mt-8 flex flex-col antialiased sm:mx-auto sm:max-w-xl md:max-w-2xl md:flex-row lg:max-w-4xl">
      <main className="mt-6 flex min-w-0 flex-auto flex-col px-2 md:px-0">
        <Navbar />
        {children}
      </main>
    </div>
    <script src="/script.js" type="application/javascript" />
  </div>
);

export const withDefault = (page: React.ReactNode, metadata?: Metadata) => (
  <Default metadata={metadata}>{page}</Default>
);
